.portfolio__header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    height: 100vh;
    padding: 4rem 0 0 0;
}

.portfolio__header-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.portfolio__header-container_navbar {
    flex: 0;
}

.portfolio__header-container_intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex: 3;
    height: 100%;
}

.portfolio__header-container_intro-text {
    width: 70%;
}

.portfolio__header-container_intro-text div div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    position: relative;
    z-index: 1;
    gap: 3rem;
}

.portfolio__header-container_intro-text h1 {
    font-family: 'Yeseva One';
    font-weight: 400;
    font-size: 76px;
    line-height: 75px;
    
    color: #FFFFFF;
}

.portfolio__header-container_intro-text p {
    font-family: 'Raleway';
    font-weight: 300;
    font-size: 20px;
    line-height: 26px;

    color: #FFFFFF;
    position: relative;
}

.portfolio__header-container_intro-text a {
    padding: 0.75rem 1.25rem;
    background: radial-gradient(117.2% 787.56% at -7.6% 100%, #5EE4C6 3.5%, #77B7F7 31%, #5358D9 55.5%, #7F5DEB 83%, #C86CE2 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    border-radius: 30px;
    transition: all 0.5s ease;
}

.gradient__button-header:hover {
    transform: scale(1.05);
    box-shadow: 0 0 12px #ffffff48;
}

.portfolio__header-container_intro-text i {
    all: unset;
    font-family: 'Protest Revolution';
    font-weight: 400;
    font-size: 116px;
    position: relative;
    top: 1rem;
    filter: brightness(1.4);
    background-image: conic-gradient(from 117.19deg at 44.58% 50%, #C86CE2 -24.88deg, #61DFC3 48.1deg, #5358D9 95.61deg, #77B7F7 132.22deg, #68D3D3 195.5deg, #46D1B2 261.03deg, #7F5DEB 299.19deg, #C86CE2 335.12deg, #61DFC3 408.1deg);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    color: transparent;
}

.portfolio__header-container_intro > div:last-child div,
.portfolio__header-container_intro-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.portfolio__header-container_intro-image img {
    width: 80%;
    filter: drop-shadow(0 0 8px #ffffff3d);
}

@media screen and (min-width: 1700px) {
    .portfolio__header-container_intro-text {
        width: 50%;
    }

    .portfolio__header-container_intro-text h1 {
        font-size: 110px;
        line-height: 110px;
    }

    .portfolio__header-container_intro-text p {
        font-size: 28px;
        line-height: 36px;
    }

    .portfolio__header-container_intro-text a {
        padding: 1rem 1.5rem;
    }

    .portfolio__header-container_intro-text i {
        font-size: 160px;
        line-height: 140px;
    }

}

@media screen and (max-width: 1000px) {
    .portfolio__header-container {
        gap: 3rem;
    }

    .portfolio__header-container_intro {
        justify-content: center;
        flex-direction: column-reverse;
        gap: 3rem;
    }

    .portfolio__header-container_intro-text {
        width: 80%;
    }

    .portfolio__header-container_intro-text div div {
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        width: 100%;
        gap: 2rem;
    }

    .portfolio__header-container_intro-text h1 {
        font-size: 80px;
        line-height: 80px;
    }

    .portfolio__header-container_intro-text p {
        font-size: 18px;
        line-height: 32px;
    }

    .portfolio__header-container_intro-image {
        justify-content: center;
        width: 100%;
    }

    .portfolio__header-container_intro-image img {
        width: 40%;
    }

    .portfolio__header-container_intro-text i {
        font-size: 100px;
        line-height: 80px;
    }
}

@media screen and (max-width: 500px) {
    .portfolio__header {
        padding-top: 2rem;
    }

    .portfolio__header-container {
        gap: 0;
    }

    .portfolio__header-container_intro-text {
        width: 100%;
        gap: 0.5rem;
    }

    .portfolio__header-container_intro-text h1 {
        font-size: 55px;
        line-height: 60px;
        margin-bottom: 2rem;
    }

    .portfolio__header-container_intro-text p:not(p:last-child) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 2rem;
    }

    .portfolio__header-container_intro-text i {
        font-size: 80px;
        line-height: 50px;
    }

    .portfolio__header-container_intro-image {
        height: inherit;
    }

    .portfolio__header-container_intro-image img {
        width: 40%;
    }
}