.portfolio__experience {
  width: 500px;
}

.portfolio__experience-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.decoration-div {
  padding: 3px 0;
  border-radius: 2px;
  margin-bottom: 5px;
  width: 8%;
  filter: brightness(1.2);
  background: radial-gradient(
      117.2% 787.56% at -7.6% 100%,
      #5ee4c6 3.5%,
      #77b7f7 31%,
      #5358d9 55.5%,
      #7f5deb 83%,
      #c86ce2 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.portfolio__experience-content div div > h3 {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
}

.portfolio__experience-content div div p {
  font-family: "Raleway";
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  text-align: justify;
  color: #ffffff;
}

.portfolio__experience-content div div a {
  display: inline-block;
}

@media screen and (min-width: 1700px) {
  .portfolio__experience {
    width: 650px;
  }

  .portfolio__experience-content div {
    padding: 4px 0;
  }

  .portfolio__experience-content h3 {
    font-size: 32px;
    line-height: 40px;
  }

  .portfolio__experience-content p {
    font-size: 28px;
    line-height: 36px;
  }
}

@media screen and (max-width: 1000px) {
  .portfolio__experience {
    width: 500px;
  }

  .portfolio__experience-content h3 {
    font-size: 24px;
    line-height: 30px;
  }

  .portfolio__experience-content p {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 500px) {
  .portfolio__experience {
    width: 100%;
  }

  .portfolio__experience-content h3 {
    font-size: 22px;
    line-height: 28px;
  }

  .portfolio__experience-content p {
    font-size: 16px;
    line-height: 22px;
  }

  .portfolio__experience-content div div p {
    font-size: 15px;
    line-height: 24px;
  }
}
