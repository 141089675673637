.portfolio__footer {
    height: 500px;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: rgba(4, 13, 18, 0.3);
}

.portfolio__footer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}

.portfolio__footer-container_quote {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.portfolio__footer-container_quote h1 {
    font-family: 'Yeseva One';
    font-weight: 400;
    font-size: 32px;
    line-height: 37px;
    text-align: center;
    margin-bottom: 1rem;
    width: 60%;
    color: #FFFFFF;
}

.portfolio__footer-container_quote p {
    font-family: 'Raleway';
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    width: 40%;
    align-self: flex-end;
    color: #FFFFFF;
}

.portfolio__footer-container_links {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 60%;
}

.portfolio__footer-container_links a {
    cursor: auto;
}

.portfolio__footer-container_links img {
    cursor: pointer;
    transition: all 0.3s ease;
}

.portfolio__footer-container_links img:hover {
    transform: scale(1.1);
}

@media screen and (min-width: 1700px) {
    .portfolio__footer-container_quote h1 {
        font-size: 42px;
        line-height: 47px;
    }

    .portfolio__footer-container p {
        font-size: 20px;
        line-height: 28px;
    }
}

@media screen and (max-width: 1000px) {
    .portfolio__footer-container_quote h1 {
        font-size: 26px;
        line-height: 34px;
    }

    .portfolio__footer-container p {
        font-size: 16px;
        line-height: 24px;
    }

    .portfolio__footer-container_links img {
        width: 40px;
    }
}

@media screen and (max-width: 500px) {
    .portfolio__footer {
        height: 400px;
    }

    .portfolio__footer-container_quote h1 {
        width: 100%;
        font-size: 22px;
        line-height: 30px;
    }

    .portfolio__footer-container p {
        font-size: 14px;
        line-height: 22px;
    }

    .portfolio__footer-container_links {
        width: 100%;
    }

    .portfolio__footer-container_links img {
        width: 32px;
    }
}