.portfolio__project {
  border-radius: 15px;
}

.portfolio__project:hover {
  animation: backgroundAnimation 0.6s ease forwards;
}

.portfolio__project-container:hover {
  background: rgba(0, 0, 0, 0.8);
}

.portfolio__project-container:hover h3 {
  opacity: 1;
}

.portfolio__project-container:hover p {
  opacity: 1;
}

@keyframes backgroundAnimation {
  from {
    background-size: 100%;
    background-position: center;
  }
  to {
    background-size: 130%;
    background-position: center;
  }
}

.portfolio__project-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 2rem 1rem;
  height: 280px;
  width: 100%;
  cursor: pointer;
  transition: 0.6s ease-in-out;
  background: rgba(0, 0, 0, 0);
  border-radius: 15px;
}

.portfolio__project-container h3 {
  display: flex;
  align-items: center;
  flex: 2;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  opacity: 0;
  transition: 0.6s ease;
}

.portfolio__project-container p {
  display: flex;
  align-items: center;
  font-family: "Raleway";
  flex: 2;
  font-weight: 200;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  opacity: 0;
  transition: 0.6s ease;
}

.portfolio__project-container > p:last-child {
  font-weight: 400;
  font-size: 18px;
  font-style: oblique;
}

@media screen and (min-width: 1700px) {
  .portfolio__project-container {
    height: 475px;
  }

  .portfolio__project-container h3 {
    font-size: 30px;
    line-height: 38px;
  }

  .portfolio__project-container p {
    font-size: 24px;
    line-height: 32px;
  }

  .portfolio__project-container p:last-child {
    font-size: 28px;
    line-height: 36px;
  }
}

@media screen and (max-width: 1000px) {
  .portfolio__project-container {
    height: 220px;
  }

  .portfolio__project-container h3 {
    font-size: 18px;
    line-height: 26px;
  }

  .portfolio__project-container p {
    font-size: 12px;
    line-height: 18px;
  }

  .portfolio__project-container p:last-child {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 500px) {
  .portfolio__project-container {
    height: 350px;
  }

  .portfolio__project-container h3 {
    font-size: 20px;
    line-height: 26px;
  }

  .portfolio__project-container p {
    font-size: 14px;
    line-height: 24px;
  }

  .portfolio__project-container p:last-child {
    font-size: 18px;
    line-height: 24px;
  }
}
