.portfolio__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.portfolio__navbar-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    color: #fff;
}

.portfolio__navbar-links_logo h3 {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
}

.portfolio__navbar-links_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

.portfolio__navbar-links_container p {
    font-family: 'Raleway';
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    cursor: pointer;
}

.portfolio__navbar-links_container p:last-child {
    padding: 0;
    margin: 0;
}

.portfolio__navbar-links_container a {
    margin-left: 4rem;
} 

.links-hover {
    transition: all 400ms ease;
}

.links-hover:hover {
    color: #b9b8b8;
}

.portfolio__navbar-menu {
    display: none;
    position: relative;
}

.portfolio__navbar-menu_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: right;
    position: absolute;
    border-radius: 5px;
    background-color: #040d1290;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    color: #fff;
    z-index: 3;
}

.portfolio__navbar-menu_content p {
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    margin: 0.5rem 0;
}

.portfolio__navbar-menu_content a:last-child {
    border: none;
    padding: 0;
}

.portfolio__navbar-menu_content a:last-child:hover {
    all: unset;
    cursor: pointer;
}

.portfolio__navbar-menu_content a:last-child p:hover {
    color: #b9b8b8;
}

@media screen and (min-width: 1700px) {
    .portfolio__navbar-links_logo h3 {
        font-family: 'Raleway';
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
    }

    .portfolio__navbar-links_container p {
        font-family: 'Raleway';
        font-weight: 300;
        font-size: 28px;
        line-height: 32px;
    }

    .portfolio__navbar-links_container a:last-child {
        padding: 0.85rem 1.75rem;
    }
}

@media screen and (max-width: 1000px) {
    .portfolio__navbar-links_logo h3 {
        font-weight: 400;
    }
}

@media screen and (max-width: 750px) {
    .portfolio__navbar-links_container {
        display: none;
    }

    .portfolio__navbar-links_logo h3 {
        font-size: 18px;
        line-height: 20px;
    }

    .portfolio__navbar-menu {
        display: flex;
    }

    .portfolio__navbar-menu_content {
        right: 0;
        top: 1.6rem;
        gap: 1.5rem;
        padding: 1.5rem 2rem;
    }
}

@media screen and (max-width: 500px) {
    .portfolio__navbar-links_logo h3 {
        font-size: 18px;
        line-height: 18px;
        margin: 0;
    }

    .portfolio__navbar-menu_content p {
        font-size: 18px;
        line-height: 18px;
    }
}