.portfolio__experiences {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0;
}

.portfolio__experiences-title h1{
    font-family: 'Yeseva One';
    font-weight: 400;
    font-size: 32px;
    line-height: 37px;
    margin-bottom: 1rem;
    color: #FFFFFF;
}

.portfolio__experiences-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: rgba(4, 13, 18, 0.3);
    box-shadow: 0px 10.0208px 100.208px rgba(129, 143, 180, 0.1);
    max-width: 100%;
    position: relative;
    overflow: hidden;
}

.portfolio__experiences-overlay_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 0;
}

.portfolio__experiences-overlay_info-map {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.portfolio__experience-content h3, .portfolio__experience-content p:not(p:last-child) {
    margin-bottom: 1rem;
}

.portfolio__experiences-overlay_info-map .portfolio__experience-content > :last-child {
    align-self: flex-start;
}

.portfolio__experiences-overlay_info-map > :nth-child(2)  {
    align-self: center;
}

.portfolio__experiences-overlay_info-map > :nth-child(3)  {
    align-self: flex-end;
}

.portfolio__experiences-overlay_images img {
    position: absolute;
    z-index: -1;
    width: 200px;
    filter: drop-shadow(0 0 10px #ffffff6a) blur(1px) brightness(1.1);
}

.second-image {
    animation: SECOND_IMAGE 12s infinite linear;
}

@keyframes SECOND_IMAGE {
    0% {
        right: 0;
        top: -25%;
        transform: rotate(0deg);
    }
    100% {
        right: 100%;
        top: 100%;
        width: 100px;
        transform: rotate(300deg);
    }
}

.third-image {
    animation: THIRD_IMAGE 14s infinite linear;
}

@keyframes THIRD_IMAGE {
    0% {
        left: -25%;
        bottom: 0;
        transform: rotate(0deg);
    }
    100% {
        left: 100%;
        bottom: 100%;
        width: 70px;
        transform: rotate(360deg);
    }
}

.fifth-image {
    animation: FIFTH_IMAGE 10s infinite linear;
}

@keyframes FIFTH_IMAGE {
    0% {
        left: 40%;
        bottom: -25%;
        transform: rotate(0deg);
    }
    100% {
        left: 10%;
        bottom: 100%;
        width: 100px;
        transform: rotate(360deg);
    }
}

.seventh-image {
    animation: SEVENTH_IMAGE 6s infinite linear;
}

@keyframes SEVENTH_IMAGE {
    0% {
        left: 20%;
        top: -25%;
        transform: rotate(0deg);
    }
    100% {
        left: 60%;
        top: 100%;
        width: 100px;
        transform: rotate(360deg);
    }
}

.nineth-image {
    animation: NINETH_IMAGE 10s infinite linear;
}

@keyframes NINETH_IMAGE {
    0% {
        left: -30%;
        top: 40%;
        transform: rotate(0deg);
    }
    100% {
        left: 100%;
        top: 60%;
        width: 70px;
        transform: rotate(300deg);
    }
}

.tenth-image {
    animation: TENTH_IMAGE 6s infinite linear;
}

@keyframes TENTH_IMAGE {
    0% {
        right: -25%;
        bottom: 20%;
        transform: rotate(0deg);
    }
    100% {
        right: 100%;
        bottom: 60%;
        width: 100px;
        transform: rotate(360deg);
    }
}

@media screen and (min-width: 1700px) {
    .portfolio__experience-content h3, .portfolio__experience-content p:not(p:last-child) {
        margin-bottom: 1.25rem;
    }

    .portfolio__experiences-title h1{
        font-size: 42px;
        line-height: 47px;
    }

    .portfolio__experiences-overlay_info-map .portfolio__experience-content > :last-child {
        padding: 0.85rem 1.75rem;
    }
}

@media screen and (max-width: 1000px) {
    .portfolio__experiences {
        padding-top: 4rem;
    }

    .portfolio__experience-content h3, .portfolio__experience-content p:not(p:last-child) {
        margin-bottom: 0.75rem;
    }

    .portfolio__experiences-overlay_info-map >:nth-child(1),
    .portfolio__experiences-overlay_info-map >:nth-child(2)  {
        margin-bottom: 1.5rem;
    }

    .portfolio__experiences-overlay_images img {
        width: 170px;
    }
}

@media screen and (max-width: 500px) {
    .portfolio__experiences-title h1{
        font-size: 28px;
        line-height: 32px;
    }

    .portfolio__experiences-overlay_info-map div {
        align-self: flex-start;
    }

    .portfolio__experiences-overlay_images img {
        width: 120px;
    }
}


